import { Capacitor, Plugins } from '@capacitor/core';
import firebase from 'firebase/app';
import 'firebase/database'; // If using Firebase database
import 'firebase/storage'; // If using Firebase storage
import { eventChannel } from 'redux-saga';
import { call, fork, put, select, spawn, take } from 'redux-saga/effects';
import WebFont from 'webfontloader';
import { getConfig, updateConfig } from '../../appConfig';
import api from '../../lib/api';
import asyncStorage from '../../lib/asyncStorage';
import Basket from '../../lib/basket';
import Stripe from '../../lib/stripe';
import { translate } from '../../lib/translate';
import { deepCopy, isDefined, isEmptyObject, mergeConfigs, mergeTranslate, sortLocations } from '../../lib/utils';
import { initRouter } from '../../navConfig';
import { setCatalog } from '../../translationCatalogWrapper';
import { GET_ORDER_HISTORY, SET_ORDERS_PROP } from '../orders/constants';
import { GET_PROFILE, GET_VOUCHERS, LOGOUT, UPDATE_PROFILE } from '../profile/constants';
import { restoreAuthSaga, startPeriodicalSaga } from '../profile/sagas';
import { GET_RESTAURANTS_SNOOZED_DATA, GET_TABLE_BY_ID, GET_TAX_DATA, SET_RESTAURANT_PROP, SET_TABLE_DATA } from '../restaurants/constants';
import { filterMenu, getTableNumbers } from '../restaurants/sagas';
import { errorHandlerSaga } from '../sagas';
import { showToast } from './actions';
import {
	ADD_TO_APPLE_WALLET,
	ADD_TO_WALLET,
	GET_ALLERGENS,
	GET_FAQ,
	GET_LOYALTY_INFO,
	GET_MILESTONE_INFO,
	GET_ORDER_PRODUCTION_MINS,
	GET_PRIVACY_POLICY,
	GET_SOCIALS,
	GET_TERMS,
	GET_UNLOCK_INFO,
	INIT,
	INIT_FIREBASE,
	INIT_FIREBASE_DATABASE,
	INIT_FIREBASE_LISTENER,
	LOADING,
	LOCATION,
	REQUEST_ERROR,
	REWARDS_INFO,
	SEND_FEEDBACK,
	SEND_FIREBASE_TOKEN,
	SET_COMMON_MODAL,
	SET_COMMON_PROP,
	SET_NAVCONFIG,
} from './constants';
import { getTaxData } from '../actions';

const { SplashScreen, PushNotifications, Device } = Plugins;

export const loading = function* (fn = null) {
	try {
		if (!isDefined(fn)) {
			return;
		}
		yield put({ type: LOADING, loading: true });
		if (fn) {
			yield call(fn);
		}
	} finally {
		yield put({ type: LOADING, loading: false });
	}
};

/**
 * send firebase token saga
 */
// TO DO: CHECK THIS (AP)
export const sendFirebaseToken = function* () {
	while (true) {
		const request = yield take(SEND_FIREBASE_TOKEN);
		yield call(loading, function* () {
			try {
				yield call(api.sendFirebaseToken, request.args);
			} catch (error) {
				yield put({ type: REQUEST_ERROR, error: error.message });
			}
		});
	}
};

/* On init app */
export const initSaga = function* () {
	while (true) {
		yield take(INIT);
		yield call(api.createAxiosInstance);
		const config = yield call(api.getFrontEndAppConfig);
		const navConfig = yield call(initRouter, config);
		yield put({ type: SET_NAVCONFIG, data: navConfig });

		const token = yield call(asyncStorage.getItem, 'token');
		const profile = yield call(asyncStorage.getItem, 'profile');
		try {
			if (token && profile && profile !== '{}') {
				const refreshTokenResponse = yield call(api.refreshToken, { refresh: token });
				if (refreshTokenResponse && refreshTokenResponse.data && refreshTokenResponse.data.token) {
					const newToken = refreshTokenResponse.data.token;
					yield call(asyncStorage.setItem, 'token', newToken);
					yield call(api.createAxiosInstance, newToken);
				}
			}
		} catch (error) {
			yield put({ type: LOGOUT });
		}

		yield put({ type: SET_COMMON_PROP, key: 'clientStyles', value: config.styles });
		const { hasOrdering, hasBillPay } = config.front_end_app_config.flags;

		const frontEndAppConfig = { ...config.front_end_app_config, timezone: config.timezone || 'Europe/London' };
		const client = yield call(api.getClient);

		document.title = client.buisiness_name;
		const favicon_image = client.favicon_image;
		const favicon = document.getElementById('favicon');
		favicon.href = favicon_image;
		yield put({ type: SET_COMMON_PROP, key: 'clientProfile', value: JSON.parse(JSON.stringify(client)) });
		if (getConfig().payment !== 'judopay' || !frontEndAppConfig.flags.hasYocoPayment) {
			// get public stripe key from BO
			const publicStripeKey = yield call(api.getPublicStripeKey);
			frontEndAppConfig.services.stripe_key = publicStripeKey;
			//init stripe plugin
			yield call(Stripe.setStripePublishableKey, publicStripeKey);
		}

		setCustomDesign(config.styles, config.custom_css, config.images);

		const mergedConfig = mergeConfigs(frontEndAppConfig, getConfig());

		updateConfig(mergedConfig);

		// get device language
		if (!mergedConfig.localization) {
			mergedConfig.localization = {};
			updateConfig(mergedConfig);
		}
		const localization = getConfig().localization;
		const deviceLanguageCode = yield call(Device.getLanguageCode);
		const sysLocale = deviceLanguageCode.value.substr(0, 2);
		if (localization && localization.supportedLocales && localization.supportedLocales[sysLocale]) {
			mergedConfig.localization.defaultLocale = sysLocale;
			updateConfig(mergedConfig);
		}

		let translationCatalog = {};
		try {
			translationCatalog = yield call(api.getTranslations);
		} catch (error) {
			// xxx
		}

		let translationCatalogFromAdmin = frontEndAppConfig && frontEndAppConfig.client && frontEndAppConfig.client.translation_json ? frontEndAppConfig.client.translation_json : {};

		const mergedTranslate = mergeTranslate(translationCatalogFromAdmin, translationCatalog);
		yield call(setCatalog, mergedTranslate);
		yield put({
			type: SET_COMMON_PROP,
			key: 'translationCatalog',
			value: mergedTranslate,
		});

		// remove all console.log messages if appConfig.general.debug is FALSE
		if (console && isDefined(getConfig().general.debug) && !getConfig().general.debug) {
			// don't try this at home :)
			// eslint-disable-next-line no-console
		}

		yield call(restoreAuthSaga);

		//add firebase listeners for push notification
		yield put({ type: INIT_FIREBASE });
		//add firebase listeners for database
		try {
			yield spawn(firebaseDatabase);
		} catch (e) {
			yield call(errorHandlerSaga, e);
		}
		if (hasBillPay) {
			const basketFromStorage = yield call(asyncStorage.getItem, 'basket');
			const bill_data = JSON.parse(basketFromStorage)?.bill_data;
			if (!isEmptyObject(bill_data)) {
				yield put({
					type: INIT_FIREBASE_LISTENER,
					tableNumber: bill_data.table_number,
					tableBillId: bill_data.id,
				});
			}
			yield put({ type: SET_TABLE_DATA, key: 'tableData', value: bill_data });
		}

		yield call(startPeriodicalSaga);
		//add app version
		const appVersion = yield call(api.getAppVersion);
		yield put({ type: SET_COMMON_PROP, key: 'appVersion', value: appVersion });

		const deliveryRangeType = yield call(api.getDeliveryRangeType);
		yield put({
			type: SET_COMMON_PROP,
			key: 'deliveryRangeType',
			value: deliveryRangeType,
		});

		const defaultMenuId = yield call(api.getDefaultMenuId);
		yield put({
			type: SET_COMMON_PROP,
			key: 'defaultMenuId',
			value: defaultMenuId,
		});

		try {
			const defaultMenu = yield call(api.getDefaultMenu, defaultMenuId);
			yield put({
				type: SET_RESTAURANT_PROP,
				key: 'defaultMenu',
				value: { ...defaultMenu, menuName: `${defaultMenu.menuName} Default` },
				merge: true,
			});
		} catch (e) {
			// xxx
		}

		const restaurants = yield call(api.getRestaurants);
		yield put({
			type: SET_RESTAURANT_PROP,
			key: 'restaurants',
			value: restaurants,
		});
		let businessLocationIds = [];
		restaurants.map((restaurant) => {
			return businessLocationIds.push(restaurant.pos_location_id);
		});
		yield call(getTableNumbers, businessLocationIds.filter(Boolean));
		const allergens = yield call(api.getAllergens);
		yield put({
			type: SET_RESTAURANT_PROP,
			key: 'allergens',
			value: allergens,
		});

		yield put({ type: GET_ORDER_PRODUCTION_MINS });
		if (hasOrdering) {
			yield call(Basket.import, null, client.is_multi_basket);
		}
		if (Basket.getMenu() && !isEmptyObject(Basket.getRestaurant())) {
			let businessLocationId = Basket.getRestaurant().business_location_id;
			let ikentooMenu = yield call(api.getIkenooMenu, Basket.getMenu(), businessLocationId);
			ikentooMenu = yield call(filterMenu, deepCopy(ikentooMenu));
			yield put({
				type: SET_RESTAURANT_PROP,
				key: 'ikentooMenu',
				value: ikentooMenu,
			});
		}
		SplashScreen.hide();
		yield put({ type: SET_COMMON_PROP, key: 'initLoading', value: false });
	}
};

const setCustomDesign = (styles, customCss, images) => {
	let documentStyle = document.createElement('style');
	let css = customCss;
	let theme = styles.theme || 'white';
	const themeWrapper = document.querySelector('body');
	const invertSideMenu = styles.invertSideMenu;
	if (styles.colors) {
		Object.entries(styles.colors).map(([key, value]) => {
			if (styles.colors['--okx-dashboard-card-background-gradient']) {
				themeWrapper.style.setProperty('--okx-dashboard-card-background-gradient', styles.colors['--okx-dashboard-card-background-gradient']);
			}
			if (styles.colors['--ion-color-primary']) {
				themeWrapper.style.setProperty('--ion-color-primary', styles.colors['--ion-color-primary']);
			}
			if (styles.colors['--ion-color-secondary']) {
				themeWrapper.style.setProperty('--ion-color-secondary', styles.colors['--ion-color-secondary']);
			}
			if (styles.colors['--ion-color-primary-contrast']) {
				themeWrapper.style.setProperty('--ion-color-primary-contrast', styles.colors['--ion-color-primary-contrast']);
			}
			if (styles.colors['--okx-dashboard-background']) {
				themeWrapper.style.setProperty('--okx-dashboard-background', styles.colors['--okx-dashboard-background']);
			}
			if (styles.colors['--ion-color-success']) {
				themeWrapper.style.setProperty('--ion-color-success', styles.colors['--ion-color-success']);
			}
			if (styles.colors['--ion-color-success-contrast']) {
				themeWrapper.style.setProperty('--ion-color-success-contrast', styles.colors['--ion-color-success-contrast']);
			}
			if (styles.colors['--ion-color-danger']) {
				themeWrapper.style.setProperty('--ion-color-danger', styles.colors['--ion-color-danger']);
			}
			if (styles.colors['--ion-color-danger-contrast']) {
				themeWrapper.style.setProperty('--ion-color-danger-contrast', styles.colors['--ion-color-danger-contrast']);
			}
			if (styles.colors['--ion-color-warning']) {
				themeWrapper.style.setProperty('--ion-color-warning', styles.colors['--ion-color-warning']);
			}
			if (styles.colors['--ion-color-warning-contrast']) {
				themeWrapper.style.setProperty('--ion-color-warning-contrast', styles.colors['--ion-color-warning-contrast']);
			}
			if (theme == 'dark') {
				themeWrapper.style.setProperty('--okx-background-color', '#4D4D4D');
				themeWrapper.style.setProperty('--okx-box-wrapper-background', '#292929');
				themeWrapper.style.setProperty('--okx-box-wrapper-color', '#ffffff');
				themeWrapper.style.setProperty('--okx-textfield-background', '#000000');
				themeWrapper.style.setProperty('--okx-textfield-color', '#FFFFFF');
				themeWrapper.style.setProperty('--okx-textfield-border', '#000000');
				themeWrapper.style.setProperty('--ion-color-gray', '#8c8b8b');
				themeWrapper.style.setProperty('--okx-menu-separator-color', '#8c8b8b');
				themeWrapper.style.setProperty('--okx-menu-background', '#292929');
				themeWrapper.style.setProperty('--okx-nav-title-color', '#acacac');
				if (invertSideMenu) {
					themeWrapper.style.setProperty('--okx-menu-background', styles.colors['--ion-color-primary']);
					themeWrapper.style.setProperty('--okx-menu-color', styles.colors['--ion-color-primary-contrast']);
					themeWrapper.style.setProperty('--okx-menu-icon-color', styles.colors['--ion-color-primary-contrast']);
					themeWrapper.style.setProperty('--okx-menu-button-text', styles.colors['--ion-color-primary']);
					themeWrapper.style.setProperty('--okx-menu-button-background', styles.colors['--ion-color-primary-contrast']);
				} else {
					themeWrapper.style.setProperty('--okx-menu-icon-color', '#fffff');
					themeWrapper.style.setProperty('--okx-menu-color', '#ffffff');
					themeWrapper.style.setProperty('--okx-menu-button-text', '#000000');
					themeWrapper.style.setProperty('--okx-menu-button-background', '#ffffff');
					themeWrapper.style.setProperty('--okx-menu-icon-color', '#ffffff');
				}
			} else if (theme == 'light') {
				themeWrapper.style.setProperty('--okx-background-color', '#F5F5F5');
				themeWrapper.style.setProperty('--okx-box-wrapper-background', '#ffffff');
				themeWrapper.style.setProperty('--okx-box-wrapper-color', '#000000');
				themeWrapper.style.setProperty('--okx-textfield-background', '#ffffff');
				themeWrapper.style.setProperty('--okx-textfield-color', '#000000');
				themeWrapper.style.setProperty('--okx-textfield-border', '#ffffff');
				themeWrapper.style.setProperty('--ion-color-gray', '#EBEBEB');
				themeWrapper.style.setProperty('--okx-menu-separator-color', '#EBEBEB');

				themeWrapper.style.setProperty('--okx-nav-title-color', '#acacac');
				if (invertSideMenu) {
					themeWrapper.style.setProperty('--okx-menu-background', styles.colors['--ion-color-primary']);
					themeWrapper.style.setProperty('--okx-menu-color', styles.colors['--ion-color-primary-contrast']);
					themeWrapper.style.setProperty('--okx-menu-icon-color', styles.colors['--ion-color-primary-contrast']);
					themeWrapper.style.setProperty('--okx-menu-button-text', styles.colors['--ion-color-primary']);
					themeWrapper.style.setProperty('--okx-menu-button-background', styles.colors['--ion-color-primary-contrast']);
				} else {
					themeWrapper.style.setProperty('--okx-menu-background', '#ffffff');
					themeWrapper.style.setProperty('--okx-menu-color', '#000000');
					themeWrapper.style.setProperty('--okx-menu-icon-color', '#000000');
					themeWrapper.style.setProperty('--okx-menu-button-text', '#ffffff');
					themeWrapper.style.setProperty('--okx-menu-button-background', '#000000');
				}
			} else {
				if (styles.colors['--ion-color-secondary-contrast']) {
					themeWrapper.style.setProperty('--ion-color-secondary-contrast', styles.colors['--ion-color-secondary-contrast']);
				}
				if (styles.colors['--okx-background-color']) {
					themeWrapper.style.setProperty('--okx-background-color', styles.colors['--okx-background-color']);
				}
				if (styles.colors['--okx-box-wrapper-background']) {
					themeWrapper.style.setProperty('--okx-box-wrapper-background', styles.colors['--okx-box-wrapper-background']);
				}
				if (styles.colors['--okx-box-wrapper-color']) {
					themeWrapper.style.setProperty('--okx-box-wrapper-color', styles.colors['--okx-box-wrapper-color']);
				}
				if (styles.colors['--okx-textfield-background']) {
					themeWrapper.style.setProperty('--okx-textfield-background', styles.colors['--okx-textfield-background']);
				}
				if (styles.colors['--okx-textfield-color']) {
					themeWrapper.style.setProperty('--okx-textfield-color', styles.colors['--okx-textfield-color']);
				}
				if (styles.colors['--okx-textfield-border']) {
					themeWrapper.style.setProperty('--okx-textfield-border', styles.colors['--okx-textfield-border']);
				}
				if (styles.colors['--okx-menu-background']) {
					themeWrapper.style.setProperty('--okx-menu-background', styles.colors['--okx-menu-background']);
				}
				if (styles.colors['--okx-menu-color']) {
					themeWrapper.style.setProperty('--okx-menu-color', styles.colors['--okx-menu-color']);
				}
				if (styles.colors['--okx-menu-icon-color']) {
					themeWrapper.style.setProperty('--okx-menu-icon-color', styles.colors['--okx-menu-icon-color']);
				}
				if (styles.colors['--okx-menu-button-text']) {
					themeWrapper.style.setProperty('--okx-menu-button-text', styles.colors['--okx-menu-button-text']);
				}
				if (styles.colors['--okx-menu-button-background']) {
					themeWrapper.style.setProperty('--okx-menu-button-background', styles.colors['--okx-menu-button-background']);
				}
				if (styles.colors['--okx-menu-separator-color']) {
					themeWrapper.style.setProperty('--okx-menu-separator-color', styles.colors['--okx-menu-separator-color']);
				} else {
					themeWrapper.style.setProperty('--okx-menu-separator-color', '#EBEBEB');
				}
				if (styles.colors['--ion-color-gray']) {
					themeWrapper.style.setProperty('--ion-color-gray', styles.colors['--ion-color-gray']);
				} else {
					themeWrapper.style.setProperty('--ion-color-gray', '#EBEBEB');
				}
			}
			return true;
		});
	}

	if (styles.fontSizes) {
		Object.entries(styles.fontSizes).map(([key, value]) => {
			themeWrapper.style.setProperty(key, value);
			return true;
		});
	}

	if (styles.fonts) {
		Object.entries(styles.fonts).map(([key, value]) => {
			WebFont.load({
				google: {
					families: [value],
				},
			});
			themeWrapper.style.setProperty(key, value);
			return true;
		});
	}

	if (styles.isRoundButton) {
		css += `ion-button, .delivery-options-menu ion-item {
	  --border: 1px solid;
	  --border-radius: 20px;
	  border-radius: 20px;
	}`;
	}

	setCustomImages(images, documentStyle, css);
};

const setCustomImages = (images, sheet, css) => {
	if (images.favicon_image) {
		let link = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement('link');
			link.rel = 'icon';
			document.getElementsByTagName('head')[0].appendChild(link);
		}
		link.href = images.favicon_image;
	}

	if (images.main_image) {
		css += `
	.web .ion-page > ion-content {
	  background-image: url(${images.main_image});
	  background-repeat: no-repeat;
	  background-position: 50% 0;
	  background-size: cover;
	}
	ion-content.route-delivery-options,ion-content div.refer-a-friend{
	  background-image: url(${images.main_image});

	}

	`;
	}

	sheet.innerHTML = css;
	document.body.appendChild(sheet);
};

/* social Saga */
export const socialSagaFlow = function* () {
	while (true) {
		yield take(GET_SOCIALS);
		//get socials
		yield call(loading, function* () {
			const social = yield call(api.getSocialLinks);
			yield put({ type: SET_COMMON_PROP, key: 'social', value: social });
		});
	}
};

/* send Feedback Saga */
export const sendFeedbackSaga = function* () {
	while (true) {
		const action = yield take(SEND_FEEDBACK);
		yield call(loading, function* () {
			const { food, service, commentService, commentTech, selectedRestaurant, customerService, orderTypeFeedback } = action.data;
			const customerServicePayload = {
				selected_restaurant: selectedRestaurant,
				feedback_type: 'app customer_service',
				food_score: food,
				service_score: service,
				feedback_response: commentService,
				order_type: orderTypeFeedback,
			};
			const techServicePayload = {
				selected_restaurant: selectedRestaurant,
				feedback_type: 'app tech_support',
				feedback_response: commentTech,
				order_type: orderTypeFeedback,
			};
			//send feedback
			if (customerService) {
				yield call(api.sendFeedback, customerServicePayload);
			} else {
				yield call(api.sendFeedback, techServicePayload);
			}
			yield put({
				type: SET_COMMON_MODAL,
				modal: 'isFeedbackModalOpen',
				value: true,
			});
		});
	}
};

/* terms Saga */
export const getTermsFlow = function* () {
	while (true) {
		yield take(GET_TERMS);
		const client_name = yield select((store) => store.common.clientProfile.name);
		const locale = yield call(getLocaleFlow);
		yield call(loading, function* () {
			const terms = yield call(api.getTerms, locale, client_name);
			yield put({ type: SET_COMMON_PROP, key: 'terms', value: terms });
		});
	}
};

/* policy Saga */
export const getPrivacyPolicyFlow = function* () {
	while (true) {
		yield take(GET_PRIVACY_POLICY);
		const client_name = yield select((store) => store.common.clientProfile.name);
		const locale = yield call(getLocaleFlow);
		yield call(loading, function* () {
			const privacyPolicy = yield call(api.getPrivacyPolicy, locale, client_name);
			yield put({
				type: SET_COMMON_PROP,
				key: 'privacyPolicy',
				value: privacyPolicy,
			});
		});
	}
};

/* faq Saga */
export const getFaqFlow = function* () {
	while (true) {
		yield take(GET_FAQ);
		const client_name = yield select((store) => store.common.clientProfile.name);
		const locale = yield call(getLocaleFlow);
		yield call(loading, function* () {
			const faq = yield call(api.getFaq, locale, client_name);
			yield put({ type: SET_COMMON_PROP, key: 'faq', value: faq });
		});
	}
};

/* allergens Saga */
export const getAllergensInfoFlow = function* () {
	while (true) {
		yield take(GET_ALLERGENS);
		const client_name = yield select((store) => store.common.clientProfile.name);
		const locale = yield call(getLocaleFlow);
		yield call(loading, function* () {
			const allergensInfo = yield call(api.getAllergensInfo, locale, client_name);
			yield put({
				type: SET_COMMON_PROP,
				key: 'allergensInfo',
				value: allergensInfo,
			});
		});
	}
};

/* firebase Saga */
export const firebaseFlow = function* () {
	while (true) {
		yield take(INIT_FIREBASE);
		if (Capacitor.platform !== 'web') {
			try {
				// Register with Apple / Google to receive push via APNS/FCM
				let result = { granted: false };
				try {
					result = yield call(PushNotifications.requestPermission);
				} catch (error) {
					result.granted = true;
				}
				if (result.granted) {
					yield call(PushNotifications.register);
					const firebaseChannel = eventChannel((emitter) => {
						PushNotifications.addListener('registration', (token) => {
							emitter({
								type: 'registration',
								value: token.value,
							});
						});

						// Some issue with your setup and push will not work
						PushNotifications.addListener('registrationError', (error) => {
							emitter({
								type: 'registrationError',
								value: error,
							});
						});

						// Show us the notification payload if the app is open on our device
						PushNotifications.addListener('pushNotificationReceived', (notification) => {
							emitter({
								type: 'pushNotificationReceived',
								value: notification,
							});
						});

						// Method called when tapping on a notification
						PushNotifications.addListener('pushNotificationActionPerformed', (notification) => {
							emitter({
								type: 'pushNotificationActionPerformed',
								value: notification,
							});
						});
					});

					while (true) {
						const firebaseMessage = yield take(firebaseChannel);
						// eslint-disable-next-line no-console
						console.log('firebase message', firebaseMessage);
						switch (firebaseMessage.type) {
							case 'registration': {
								yield put({
									type: SET_COMMON_PROP,
									key: 'deviceFcmToken',
									value: firebaseMessage.value,
								});
								break;
							}
							default:
						}
					}
				}
			} catch (error) {
				// eslint-disable-next-line no-console
				console.log('INIT_FIREBASE_ERROR', error);
			}
		}
	}
};

const checkFibaseDBProperty = function* (propName, obj = {}) {
	let ret = false;
	const storagePropName = propName + '_firebase';
	if (obj?.table || obj[propName]) {
		const storagePropValue = yield call(asyncStorage.getItem, storagePropName);
		if (!storagePropValue || storagePropValue !== (obj?.table || obj[propName])) {
			yield call(asyncStorage.setItem, storagePropName, obj?.table || obj[propName]);
			ret = true;
		}
	}
	return ret;
};

export const firebaseDatabaseFlow = function* () {
	while (true) {
		yield take(INIT_FIREBASE_DATABASE);
		yield call(firebaseDatabase);
	}
};

export const firebaseDatabase = function* () {
	const profileId = yield select((store) => store.profile.profile.id);
	const client_id = getConfig().client.client_id;
	const auth = yield select((store) => store.profile.auth);
	const firebaseChannel = eventChannel((emitter) => {
		const connection = !firebase.apps.length ? firebase.initializeApp(getConfig().firebaseConfig) : firebase.app();
		const restaurants = connection.database().ref('restaurants');
		const client = connection.database().ref(`client_${client_id.toString()}`);
		if (auth && auth.loggedIn) {
			const db = connection.database().ref(profileId);
			db.on('value', (snap) => {
				let db_record = snap.val();
				if (db_record) {
					emitter({ type: 'db', value: db_record });
				}
			});
		}

		restaurants.on('value', (snap) => {
			let db_record = snap.val();
			if (db_record) {
				emitter({ type: 'db', value: db_record });
			}
		});

		client.on('value', (snap) => {
			let db_record = snap.val();
			if (db_record) {
				emitter({ type: 'db', value: db_record });
			}
		});

		// unsubscribe function
		return () => {};
	});

	while (true) {
		const firebaseMessage = yield take(firebaseChannel);
		// eslint-disable-next-line no-console
		console.log('firebase database message', firebaseMessage);
		const { type, value } = firebaseMessage;
		let valueArray = Object.keys(value).map((key) => ({
			key,
			value: value[key],
		}));
		switch (type) {
			case 'db': {
				const auth = yield select((store) => store.profile.auth);
				if (auth && auth.loggedIn) {
					if (yield call(checkFibaseDBProperty, 'client_data', value)) {
						const client = yield call(api.getClient);
						document.title = client.buisiness_name;
						const favicon_image = client.favicon_image;
						const favicon = document.getElementById('favicon');
						favicon.href = favicon_image;
						yield put({
							type: SET_COMMON_PROP,
							key: 'clientProfile',
							value: client,
						});
						yield put({
							type: SET_COMMON_PROP,
							key: 'clientProfileUpdated',
							value: value.client_data,
						});
					}
					if (yield call(checkFibaseDBProperty, 'timestamp', value)) {
						yield put({ type: GET_PROFILE, skipLoading: true });
					}
					if (yield call(checkFibaseDBProperty, 'vouchers', value)) {
						// get vouhers here
						yield put({ type: GET_VOUCHERS });
					}
					if (yield call(checkFibaseDBProperty, 'order_history', value)) {
						yield put({ type: GET_ORDER_HISTORY, loading: false });
					}
					let latestOrderId = -Infinity;
					for (let i = 0; i < valueArray.length; i++) {
						let orderId = valueArray[i].key;
						let curentOrderId = +orderId;
						if (!isNaN(curentOrderId)) {
							if (curentOrderId > latestOrderId) {
								latestOrderId = curentOrderId;
							}
						}
					}
					if (yield call(checkFibaseDBProperty, latestOrderId.toString(), value)) {
						const lastOrderResult = yield call(api.getLastOrder, latestOrderId.toString());
						let orderHistory = yield select((store) => store.orders.orderHistory);
						if (lastOrderResult.is_parent_order) {
							let newOrderHistory = [...orderHistory];
							if (orderHistory && lastOrderResult) {
								newOrderHistory = newOrderHistory.map((order) => {
									const multi_basket_items = order.multi_basket_items.map((el) => {
										const updatedItem = lastOrderResult.multi_basket_items.find((item) => item.restaurant.id == el.restaurant.id);
										return updatedItem ? { ...el, status: updatedItem.status } : el;
									});
									if (order.id === lastOrderResult.id) {
										return {
											...order,
											...lastOrderResult,
											multi_basket_items,
										};
									} else {
										return order;
									}
								});
							}
							yield put({
								type: SET_ORDERS_PROP,
								key: 'orderHistory',
								value: newOrderHistory,
							});
						} else {
							const parentOrderResult = yield call(api.getLastOrder, lastOrderResult.parent_order_id);
							let parentOrderHistory = [...orderHistory];
							if (orderHistory && parentOrderResult) {
								parentOrderHistory = parentOrderHistory.map((order) => {
									if (order.id === parentOrderResult.id) {
										return {
											...order,
											...parentOrderResult,
										};
									} else {
										return order;
									}
								});
							}
							yield put({
								type: SET_ORDERS_PROP,
								key: 'orderHistory',
								value: parentOrderHistory,
							});
						}
					}
				}
				if (yield call(checkFibaseDBProperty, Basket.getUUID(), value)) {
					yield put({
						type: GET_TAX_DATA,
						uuid: Basket.getUUID(),
					});
				}
				if (yield call(checkFibaseDBProperty, 'restaurant_snooze_data', value)) {
					// get restaurants snoozed data
					yield put({ type: GET_RESTAURANTS_SNOOZED_DATA });
					yield put({
						type: SET_RESTAURANT_PROP,
						key: 'restaurantsUpdated',
						value: value.restaurant_snooze_data,
					});
				}
				break;
			}
			default:
		}
	}
};

export const saveFcmToken = function* () {
	const profile = yield select((store) => store.profile.profile);
	const deviceFcmToken = yield select((store) => store.common.deviceFcmToken);

	if (isDefined(profile) && isDefined(deviceFcmToken) && profile.fcm_token !== deviceFcmToken) {
		//save new fcm token
		yield put({
			type: UPDATE_PROFILE,
			data: { fcm_token: deviceFcmToken },
			skipAlert: true,
		});
	}
};

// fetch user profile (if exists) and translate using profile.locale OR using default locale form config
export const translateSaga = function* (text) {
	const store = yield select();
	let locale = getConfig().localization.defaultLocale;
	if (store.profile && store.profile.profile && store.profile.profile.locale) {
		locale = store.profile.profile.locale;
	}
	return translate(text, locale);
};

export const getLocaleFlow = function* () {
	const store = yield select();
	let locale = getConfig().localization.defaultLocale;
	if (store.profile && store.profile.profile && store.profile.profile.locale) {
		locale = store.profile.profile.locale;
	}
	return locale;
};

export const locationFlow = function* () {
	while (true) {
		const action = yield take(LOCATION);
		const store = yield select();
		const myLocation = action.value || store.common.myLocation;
		yield put({ type: SET_COMMON_PROP, key: 'myLocation', value: myLocation });
		const restaurants = store.restaurants.restaurants;
		if (restaurants) {
			restaurants.forEach((restaurant) => {
				if (restaurant.position) {
					const [lat, lng] = restaurant.position.split(',');
					restaurant.latitude = parseFloat(lat);
					restaurant.longitude = parseFloat(lng);
				} else {
					const lat = getConfig().services.google_maps.defaultLat;
					const lng = getConfig().services.google_maps.defaultLng;
					restaurant.position = lat + ',' + lng;
					restaurant.latitude = lat;
					restaurant.longitude = lng;
				}
			});
			const sortRestaurants = yield call(sortLocations, restaurants, myLocation);
			yield put({
				type: SET_RESTAURANT_PROP,
				key: 'restaurants',
				value: sortRestaurants,
			});
		}
	}
};

/* order minutes Saga */
export const getOrderProductionFlow = function* () {
	while (true) {
		yield take(GET_ORDER_PRODUCTION_MINS);
		const order_production_mins = yield call(api.getOrderProduction);
		yield put({
			type: SET_COMMON_PROP,
			key: 'orderProductionMins',
			value: order_production_mins,
		});
	}
};

export const addToWalletFlow = function* () {
	while (true) {
		const { data } = yield take(ADD_TO_WALLET);
		yield call(loading, function* () {
			try {
				const response = yield call(api.addToGoogleWallet, data);
				if (response.error) {
					yield put(showToast(yield call(translateSaga, response.error), 'danger'));
				} else {
					window.open(response.url, '_system', 'location=yes');
				}
			} catch (error) {
				yield put({ type: REQUEST_ERROR, error: error.message });
			}
		});
	}
};

export const addToAppleWalletFlow = function* () {
	while (true) {
		const { data } = yield take(ADD_TO_APPLE_WALLET);
		yield call(loading, function* () {
			try {
				const response = yield call(api.addToAppleWallet, data);
				if (response.error) {
					yield put(showToast(yield call(translateSaga, response.error), 'danger'));
				} else {
					const reader = new FileReader();
					reader.onloadend = async () => {
						const blobData = new Blob([response], { type: 'application/vnd.apple.pkpass' });
						const url = window.URL.createObjectURL(blobData);
						const link = document.createElement('a');
						link.href = url;
						link.download = `${data.passName}.pkpass`;
						link.click();
					};
					reader.readAsDataURL(response);
				}
			} catch (error) {
				console.log('Error adding to apple wallet:', error);
				yield put({ type: REQUEST_ERROR, error: error.message });
			}
		});
	}
};

function createChannel(data) {
	const client_id = getConfig().client.client_id;

	const ref = firebase.database().ref(`table/client_${client_id}_table_${data}`);

	const channel = eventChannel((emit) => {
		ref.on('value', (snapshot) => {
			emit({ type: 'db', value: snapshot.val() });
		});
		return () => ref.off('value');
	});

	return channel;
}

function* observeSuccesses(channel, tableBillId) {
	while (true) {
		const messages = yield take(channel);
		const basketFromStorage = yield call(asyncStorage.getItem, 'basket');
		const bill_data = JSON.parse(basketFromStorage)?.bill_data;
		const { type, value } = messages;
		if (bill_data.id == tableBillId) {
			const client_id = getConfig().client.client_id;
			switch (type) {
				case 'db':
					if (yield call(checkFibaseDBProperty, `table/client_${client_id}_table_${bill_data.table_number}`, value)) {
						yield put({ type: GET_TABLE_BY_ID, tableBillId });
					}
					break;

				default:
			}
		}
	}
}

export const firebaseListenerFlow = function* () {
	// neccessary if u need to provide cancelation logic
	const subscriptions = {};

	while (true) {
		const { tableNumber, tableBillId } = yield take(INIT_FIREBASE_LISTENER);
		const channel = createChannel(tableNumber);
		const task = yield fork(observeSuccesses, channel, tableBillId);
		subscriptions[tableNumber] = [channel, task];
	}
};

export const getMilestoneRewardsInfoFlow = function* () {
	while (true) {
		yield take(GET_MILESTONE_INFO);
		const milestoneInfo = yield call(api.getMilestoneRewardsInfo);
		yield put({ type: SET_COMMON_PROP, key: 'milestoneInfo', value: milestoneInfo });
	}
};
export const getLoyaltyInfoFlow = function* () {
	while (true) {
		yield take(GET_LOYALTY_INFO);

		const loyaltyInfo = yield call(api.getLoyaltyInfo);
		yield put({ type: SET_COMMON_PROP, key: 'loyaltyInfo', value: loyaltyInfo });
	}
};

export const getUnlockRewardsInfoFlow = function* () {
	while (true) {
		yield take(GET_UNLOCK_INFO);

		const unlockRewardsInfo = yield call(api.getUnlockRewardsInfo);
		yield put({ type: SET_COMMON_PROP, key: 'unlockRewardsInfo', value: unlockRewardsInfo });
	}
};
